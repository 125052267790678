<div id="cabecera">
  <button  (click)="ir(341,255);">Ir NO9</button>
</div>
<map name="Mapa" id="mapa">
<area shape="rect" coords="0, 0, 50, 50" href="index_OLD.html">
<!--  <area shape="circle" coords="75, 75, 25" href="violeta.html"> -->
<!-- <area shape="poly" coords="88, 79, 236, 92, 231, 167, 88, 170" href="index_OLD.html" target="_blanck"> -->
</map>
<div id="mapa" usemap="#Mapa" (click)="posicion($event)">
  <a>
    <img src="/assets/sherlock.jpg" usemap="#Mapa">
  </a>
</div>
