<div id="titulos">
  <div>Sherlock Holmes</div>
  <div>Detective asesor</div>
</div>
<div id="casos">
  <div *ngFor="let caso of casos">
      <a routerLink="/caso/{{caso.id}}">
        <img src="/assets/caso{{caso.id}}.jpg" alt="caso {{caso.id}}">
      </a>
  </div>
</div>
