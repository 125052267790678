import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit
{

  constructor() { }

  ngOnInit(): void
  {
    console.log('Ancho: ' + window.innerWidth);
    console.log("Alto: " + window.innerHeight);


  }

  posicion(evt:any)
  {
    const { currentTarget: svg, pageX, pageY } = evt;
    console.log(pageX);
    console.log(pageY);
  }

  ir(x: any, y: any)
  {
    console.log('ir');
    // console.log('Top: ' + (window.innerHeight) );
    // console.log('Left: ' + (window.innerWidth));
    console.log('Top: ' + (x + 50));
    console.log('Left: ' + (y));
    window.scroll({
      top: x + 50,
      left: y,
      behavior: 'smooth'
    });
  }
}
